.Header {
	padding: 1% 5%;
	padding-left: 7.5%;
	// background: linear-gradient(90deg, #fc0 0, #fc0 48%, #ffe57f 70%, #fff0b2);
	background: var(--color-light-grey);
	min-height: 8vh;
	display: flex;
	font-size: 1.4rem;
	// border: 1px solid #e3e3e3;
	margin: 0 auto;
	justify-content: space-between;
	.left {
		display: flex;
		align-items: center;

		img {
			height: 5rem;
		}
	}
	.main {
		display: flex;
	}
	.right {
		justify-content: center;
		align-items: center;
		display: flex;
		h4, i {
			font-weight: 500;
		}
	}
}
@media screen and (max-width: 767px) {
	.Header {
		padding-left: 1.5%;
	
		.left {
			img {
				height: 4rem;
			}
		}
		
	}
	
}
