

.InvestmentAnalysis {
    .p-accordion-header-link {
        display: flex;
        flex-direction: row-reverse;
        i {
            margin-top: 6.5rem;
            //margin-left: 1rem;
            color: #f18f2b !important;
        }
        .p-accordion-toggle-icon {
            margin-top: 1rem;
            margin-left: 2rem;
        }
        .p-accordion-header-text {
            width: 100%;
        }
    }
    
}
.Assumptions {
    .mb-2 {
        width: 70%;
        margin: auto;
    }
}
.Ratios {
    .mb-2 {
        width: 70%;
        margin: auto;
    }
}
.separatorcls {
    height: 0.5px;
    border: none;
    width: 90%;
    border-top: 0.5px solid var(--color-dark-grey) !important;
    margin-left: 0rem;
  }

  .navbarcls .nav-elements ul {
    display: flex;
    justify-content: space-around!important;
    list-style-type: none;
    border-bottom: 1px solid #dee2e6;
  }
  @media only screen and (max-width: 767px) {
    .InvestmentAnalysis {
        .p-accordion-header-link {
            i {
                margin-top: 5.5rem;
                //margin-left: 1rem;
                color: #f18f2b !important;
            }
        }
    }
  }