@import "./_variable.scss";
.HomePurchaseRentForm {
	padding: 2rem;
	width: 50%;
	margin: auto;
	.rentLabel {
		font-size: 1.4rem;
		font-weight: 400;
	}
	.widthcls-mobile {
		width: 100%;
	}
	.vw-inputnumber-pp {
		.p-inputtext {
			border-color: var(--color-dark-grey);
		}
	}
	h2 {
		display: flex;
		justify-content: center;
		font-size: 2rem;
		font-weight: 500;
	}

	h3 {
		display: flex;
		justify-content: center;
		font-size: 1.6rem;
		margin-bottom: 3rem;
		font-weight: 400;
	}

	.content {
		text-align: center;
		.rent-label {
			font-weight: 400;
			font-size: 1.4rem;
			margin-top: 1rem;
			text-align: left;
		}
		.rent-input {
			display: flex;
			justify-content: center;
			margin-bottom: 2rem;
			span {
				width: 50%;
				margin-right: 1rem;
			}
			.login-button {
				width: 20%;
			}
		}
	}
}

.HomePurchaseStep3Form {
	padding: 0rem 3rem;
	input {
		height: 4rem;
	}
	.content {
		display: flex;
		justify-content: center;
		.left {
			width: 16%;
			.p-card-body {
				//padding-top: 3.7rem;
			}
			h4 {
				font-weight: 500;
			}
			.border-top {
				border-top: 1px solid var(--color-dark-grey);
			}
			 .p-card {
			 	box-shadow: none;
			 }
		}
		.right {
			margin-left: 2rem;
			width: 60%;
			.p-panel-content {
				border: 0;
			}
			.pi {
				font-size: 1.7rem;
			}
			.pencil-icon {
				top: -3.5rem;
				left: 3rem;
				color: var(--color-warning);
			}
			.pencil-icon-1 {
				top: 1rem;
				left: 1rem;
				color: var(--color-warning);
			}
			.pencil-icon-income {
				top: 0.5rem;
			}
			.InvestmentAnalysis {
				background-color: #fff;
				.p-tabview-panels {
					background-color: #fff!important;
				}
				.p-accordion .p-accordion-header .p-accordion-header-link {
					border: 0;
					background-color: #fff;
				}
				.p-panel .p-panel-content {
					border: 0;
				}
				.p-accordion-tab {
					width: 70%;
					margin-left: 10rem;
				}
				.mb-2 {
					font-size: 1.2rem;
					font-weight: normal;
				}
				.p-accordion-content {
					border: 0;
				}
				.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
					box-shadow: none;
				}
				.w-90 {
					width: 90%;
				}
			}
			.p-tabview .p-tabview-panels {
				//background-color: var(--color-light-grey);
				//box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
				//	0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
				border-radius: 3px;
			}

			.BasicInformation {
				.p-panel-header {
					background-color: var(--color-dark-grey);
					color: #333;
					padding: 1rem;
					padding-left: 1.8rem;
					padding-right: 2rem;
					button {
						min-width: 8rem;
					}
				}
				.p-panel-content {
					padding-top: 2rem;
					padding-bottom: 0;
					.grid {
						width: 75%;
						margin: 0 auto;
						flex-wrap: nowrap;
					}
				}
			}
			.OwnHouse {
				.flex-cls {
					display: flex;
				}
				.p-panel-header {
					background-color: var(--color-dark-grey);
					color: #333;
					padding: 1rem;
					padding-left: 1.8rem;
					padding-right: 2rem;

					button {
						min-width: 8rem;
					}
				}
				.p-panel-content {
					padding-top: 2rem;
					padding-bottom: 0;
					.grid {
						width: 75%;
						margin: 0 auto;
						flex-wrap: nowrap;
					}
				}
				.margin-pp {
					margin-left: -1rem;
				}
				.margin-me {
					margin-left: -6rem;
				}
				.nowrap {
					white-space: nowrap;
				}
			}
			.PurchasePrice {
				.p-panel-content {
					padding-top: 2rem;
					padding-bottom: 0;
					.grid {
						width: 102%;
						margin: 0 auto;
						flex-wrap: nowrap;
					}
				}
				.widthCls {
					width:102%;
				}
			}
			.IncomeCalculations {
				@extend .OwnHouse;
				.p-panel-content {
					padding-top: 4rem;
					padding-bottom: 0;
					padding-right: 4rem;
					height: 43rem;
					//overflow-y: scroll;
					border: 0;
					.grid {
						width: 85%;
						margin: 0 auto;
					}
				}
				.pencil-icon-mortgage {
					top: 1rem !important;
					left: 0 !important;
				}

				.header {
					label {
						font-weight: bold;
						margin-left: 5rem;
						margin-bottom: 1rem;
					}
				}
				.PurchasePrice {
					.p-panel-content {
						padding-top: 2rem;
						padding-bottom: 0;
						.grid {
							width: 102%;
							margin: 0 auto;
							flex-wrap: nowrap;
						}
					}
					.width-cls {
						width: 120%;
					}
				}
				.grossincome {
					display: flex;
    				flex-direction: row;
				}
			}
			.MortgageStructure { 
				.p-panel-header {
					background-color: var(--color-dark-grey);
					color: #333;
					padding: 1rem;
					padding-left: 1.8rem;
					padding-right: 2rem;

				}
				.p-panel-content { 
					border: 0;
				}
				.totalDebtPayments-mt {
					margin-top: -7.25rem;
				}
				.p-panel-header {
					background-color: var(--color-light-grey);
				}
			}
		}
	}
}
.MonthlyExpenseModal {
	.pi-pencil {
		font-size: 1.7rem;
	}
		.p-inputgroup {
		//width:92%;
	}
}
.GrossIncomeModal {
	.grid-grossIncome {
		display: flex;
		flex-wrap: nowrap;
	}
	input {&.p-disabled {
		font-weight: normal!important;
	}}
}
.ml {
	margin-left: -3.5rem;
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.HomePurchaseStep3Form {
		.content {
			.right {
				margin-left: 0;
				.w-50 {
					width:45%;
				}
				.margin-3 {
					margin-left: 3rem;
				}
				.p-panel .p-panel-content {
					border: 0px;
					padding-top: 0px;
					overflow: hidden;
				}
				.pi {
					font-size: 2rem;
				}
				.save-width {
					width: 57%;
					height: 64%;
					margin-left: -8rem;
				}
				.text-align-center {
					text-align: center;
				}
				
				.IncomeCalculations {
					.p-panel-content {
						.grid { // added vertical scroll bar for ipad
							width: 100%;
						}
					}
					.grid {
						justify-content: center;
					}
					.w-90 {
						width:90%;
					}

				}
				.BasicInformation {
					.p-panel-content {
						.grid { // added vertical scroll bar for ipad
							width: 100%;
							justify-content: center;
						}
					}
					.p-panel-header {
						background-color: var(--color-light-grey);
						border: 0px;
						padding-left:5.4rem;
						padding-right: 6rem;
					}
				}
					.mb-2 {
						.w-90 {
							//display: flex;
							margin: auto;
							width: 90%;
						}
					}
				
				.OwnHouse {
					
					.w-50 {
						width:45%;
					}
					.width-cls {
						width: 109%!important;
					}
					.p-panel-content {
						.grid { // added vertical scroll bar for ipad
							width: 100%;
							justify-content: center;
						}
					}
					.p-panel-header {
						background-color: var(--color-light-grey);
						border: 0px;
						padding-left:5.4rem;
						padding-right: 6rem;
					}
					.totalexpense-width {
						width: 45%;
					}
					
					.justify-cls {
						justify-content: flex-start!important;
						
					}
					.align-text-rt {
						text-align: right;
					}
				}
				.p-tabview .p-tabview-panels {
					background-color: white;
					box-shadow:none;
					border: 0px;
					padding-left:0;
					padding-top: 0.6rem;
					padding-right: 0;
				}
				.PurchasePrice { 
					.grid {
						justify-content: center;
					}
					.pp-cls {
						display: flex;
						flex-direction: column;
						align-items: center;
					}
					.widthCls {
						width:103%;
					}
					.marginleftcls {
						margin-left: 6rem;
					}
				}
				.MortgageStructure {
					.p-panel-header {
						background-color: var(--color-light-grey);
						border: 0px;
						padding-left:5.4rem;
						padding-right: 6rem;
					}
					.w-90 {
						display: flex;
						margin: auto;
    					width: 90%;
					}
					.w-95 {
						width: 95%;
					}
					.margincls {
						margin: auto;
					}
				}
				.Settings {
					.p-panel-header {
						background-color: var(--color-light-grey);
						border: 0px;
						padding-left:4.4rem;
						padding-right: 6rem;
					}
				}
				
			}
		}
	} 
	.separator {
		width: 95%;
		border-top: 1px solid var(--color-dark-grey) !important;;
	  }
	html .p-inputtext {
		border-color: var(--color-dark-grey) !important;
		border-radius: 0px!important;
		//height: 100%;
	}
	.HomePurchaseRentForm { 
		.w-100 {
			width: 100%;
		}
	}
	.App-container {
		.container-fluid {
			background-color: white!important;
		}
		

		
	}
	.HomePurchaseStep3Form .content .right {
		width: 100%;
	}
	.margin-top-dp {
		margin-top: 1.4rem;
	}
	.margin-top-button {
		margin-top: 2.5rem;
	}
	.MonthlyExpenseModal {
		.p-inputgroup {
			//width:115%;
		}
	}
	.GrossIncomeModal {
		.text-align-center {
			text-align: center;
		}
	}
	.mr-0 {
		margin: 0!important;
	}	
}
.tabs {
    //padding: 10px;
    .tabs-nav {
      display: flex;
      align-items: center;
	  flex-direction: column;

      .nav-item {
        color: #000000;
        cursor: pointer;
        padding: 20px 10px;
		font-size: 1.4rem;
        text-transform: capitalize;
        transition: color 0.5s linear;
        -webkit-transition: color 0.5s linear;
        -o-transition: color 0.5s linear;
        -moz-transition: color 0.5s linear;
      }
      .active {
        border-bottom: 2px solid var(--color-warning);
       // color: var(--color-warning);
	    font-weight: 600;
		font-size: 1.4rem;
        transition: color 0.5s linear;
        -webkit-transition: color 0.5s linear;
        -o-transition: color 0.5s linear;
        -moz-transition: color 0.5s linear;
      }
    }
    .tabs-content {
      margin-top: 20px;
    }
  }
  .align-text-left {
	text-align: left;
  }
  @media only screen and (max-width: 767px) {
	.HomePurchaseRentForm { 
		width: 100%;
		.widthcls-mobile {
			width: 100%;
		}
	}
	.App-container {
		.container-fluid {
			background-color: white!important;
		}
	}
	html .p-inputtext {
		border-color: var(--color-dark-grey) !important;
		border-radius: 0px!important;
		//height: 100%;
	}
	.HomePurchaseStep3Form {
		padding: 0rem 0rem;
		input {
			height: 3.5rem;
		}
		.content {
			.right {
				width: 100%;
				margin: 1rem;
				.p-panel-header {
					background-color: var(--color-light-grey);
					border: 0px;
					padding-left:1.4rem;
					padding-bottom: 1.5rem;
				}
				.BasicInformation {
					.p-panel-content {
						.grid {
							width: 100%;
							margin-top: 0.5rem;
						}
					}
					.p-panel-header {
						background-color: var(--color-light-grey);
						border: 0px;
						padding-left:1.4rem;
					}
				}
				.OwnHouse {
					.p-panel-content {
						.grid {
							width: 100%;
							//margin-top: 0.5rem;
						}

					}
					.p-panel-header {
						background-color: var(--color-light-grey);
						border: 0px;
						padding-left: 1.4rem;
					}
					.totalexpense-width {
						width: 75%;
					}
					.pt-1 {
						padding-top: 0.5rem;
					}

				}
				.pencil-icon {
					top:-3rem;
					left:10.5rem;
					color: var(--color-warning);
				}
				.pencil-icon-1 {
					top:-5rem;
					left:11rem;
					color: var(--color-warning);
				}
				.IncomeCalculations {
					.p-panel-content {
						padding-right: 4rem;
						height: 100%;
    					padding-top: 2rem;
					}
					.header {
						label {
							font-weight: bold;
							margin-left: 4rem;
							margin-bottom:0px;
						}
					}

				}
				.p-tabview .p-tabview-panels {
					background-color: white;
					box-shadow:none;
					border: 0px;
					padding-left:0;
					padding-top: 1.4rem;
					padding-right: 0;
				}
				.p-panel .p-panel-content {
					border: 0px;
					padding-top: 0px;
				}
				.MortgageStructure {
					.w-90 {
						display: flex;
					}
					.w-122 {
						width: 122%;
					}
					.p-panel-header {
						background-color: var(--color-light-grey);
						border: 0px;
						padding-left:1.4rem;
						padding-bottom: 1.5rem;
					}
				}
				.nav-elements ul li {
					white-space: normal;
					text-align: center;
				  }
				  .nav-elements ul a {
					margin-bottom: 0rem;
					display: flex;
					justify-content: center;
					font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    				font-size: 13px;
					color: black;
    				font-weight: normal;
					padding: 0.5rem;
				  }
				  .nav-elements ul a.active {
					height: 125%;
				  }
				  .nav-elements ul {
					border: 1px solid #dee2e6;
					padding-top: 1rem;
				  }
			}
		}
		.InvestmentAnalysis {
			.p-accordion-tab {
				margin-left: 2rem!important;
				width: 90%!important;
			}
		}
	}
	
	.p-tabview-title {
		white-space:break-spaces;
		font-size: 13px;
		font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
		
	}
	.p-tabview .p-tabview-nav li .p-tabview-nav-link {
		border: 0px;
	}
	.p-tabview .p-tabview-nav {
		border: 0px;
	}
	html a {
		font-size: 1.1rem;
	}
	.margin-top-dp {
		margin-top: 1.4rem;
	}
	.margin-top-button {
		margin-top:2.5rem;
	}
	.MonthlyExpenseModal {
		.grid {
			flex-direction: column;
			align-items: center;
		}
		.pi-pencil {
			font-size: 1.7rem;
		}
		.p-inputgroup {
			width:104%;
		}
		.vw-input-nfl {
			padding-left: 0;
		}
		.taxbreak {
			flex-direction: row;
		}
		.margin-top-dp {
			margin-top: 3.1rem;
		}
		.bold {
			font-weight: bold;
		}
	}
	.MonthlyExpensesModal {
		.p-inputgroup {
			width:100%;
		}
		.pi-pencil {
			font-size: 1.7rem;
		}
	}
	.AnnualExpenseModal {
		.grid {
			flex-direction: column;
			align-items: center;
		}
		.pi-pencil {
			font-size: 1.7rem;
		}
		.p-inputgroup {
			//width:104%;
		}
		
	}
	.GrossIncomeModal {
		.pi-pencil {
			font-size: 1.7rem;
		}
	}
	.MortgageStructureModal {
		.grid {
			flex-direction: row;
		}
		#secondLoanPercent {
			padding: 0;
		}
		#firstLoanPercent {
			padding: 0;
		}
		.margin-top-mortgage {
			margin-top: 9.4rem !important;
		}
	}
	.save-width {
		width:159%;
		padding: 0.9rem 1rem !important;
	}
	.p-tabview-nav-content {
		border: 2px solid var(--color-light-grey);
	}
	.text-align-center {
		text-align: center;
	}
	.separator {
		width: 95%;
		border-top: 1px solid var(--color-dark-grey) !important;;
	  }
  }

  
  .container {
	//max-width: 1100px;
	margin: 0 auto;
	//padding: 0 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
  }
  

  
  .logo {
  }
  
  .menu-icon {
	display: none;
  }
  .nav-elements ul {
    display: flex;
    justify-content: space-between!important;
    list-style-type: none;
    border-bottom: 1px solid #dee2e6;
  }